import React, {useContext, useState} from "react"
import {Apis} from "../util/Apis"
import useFetch from "../hooks/useFetch"
import {CircularProgress} from "@material-ui/core"
import LudopathsList from "./generalComponents/LudopathsList"
import CustomAppBar from "./generalComponents/CustomAppBar"
import styled from "styled-components"
import BottomNav from "./generalComponents/BottomNav";
import {SiteContext} from "../selector/SiteContext";

export const CustomInput = styled.input`
  width: 100%;
  height: 45px;
  padding: 0 20px;
  font-size: 1rem;
  border: 1px solid #D0CFCE;
  outline: none;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%);
  border-radius: 5px;
  color: #5a5a5a;

  &:focus {
    border: 1px solid #182d94;
    transition: 0.35s ease;

    &::-webkit-input-placeholder {
      transition: opacity 0.45s ease;
      opacity: 0;
    }

    &::-moz-placeholder {
      transition: opacity 0.45s ease;
      opacity: 0;
    }

    &:-ms-placeholder {
      transition: opacity 0.45s ease;
      opacity: 0;
    }
  }
`
export const SearchContent = styled.div`
  padding: 1rem;
  position: sticky;
  top: 4rem;
  background-color: white;
`

export const ListContent = styled.div`
  margin: 0.5rem 1rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const GamblerList = () => {

  const [searchTerm, setSearchTerm] = useState('')
  const {salaId} = useContext(SiteContext)

  const banListId = "5f2c9bc0ccd74b07f0e929d6";
  const {data, loading} = useFetch(Apis.BASE + "/ban-list/" + banListId + "/members?page=1&size=10&salaId=" + salaId)

  return (
    <>
      <CustomAppBar titulo={'Listado de ludópatas'}/>
      <SearchContent>
        <CustomInput placeholder="Buscar por nombre o documento"
                     onChange={event => {
                       setSearchTerm(event.target.value)
                     }}
                     autoComplete="off"
                     type="text"
        />
      </SearchContent>
      <ListContent>
        {loading ?
          <CircularProgress color="secondary"/>
          :
          <LudopathsList data={data} searchTerm={searchTerm}/>
        }
      </ListContent>
      <BottomNav/>
    </>
  )
}

export default GamblerList
