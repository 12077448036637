import React from "react";
import './styles/Titles.css'

class Titles extends React.Component {
  render() {
    return (
      <div className="titles-container">
        <div className="photo-title" id="titles">
          Foto
        </div>
        <b className="numRegis" id="titles">
          Nro
          <br/>
          Registro
        </b>
        <div className="person" id="titles">
          Persona
        </div>
        <b className="location" id="titles">
          Ubicación
        </b>
        <b className="date" id="titles">
          Publicación
        </b>
      </div>
    )
  }
}

export default Titles
