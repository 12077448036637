import React from "react";
import locIcon from './images/location-icon.png'
import './styles/Location.css'

class Location extends React.Component {
  render() {
    return (
      <div className="location-container">
        <div className="location-name-container">
          <img className="location-arrow-image" src={locIcon} alt="arrow-icon"/>
          <div className="location-place">
            {this.props.distrito}
          </div>
        </div>
        <div className="location-city-container">
          {this.props.provincia},{this.props.departamento}
        </div>
      </div>
    )
  }
}


export default Location
