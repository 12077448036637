import React from 'react'
import styled from 'styled-components'
import {NavLink} from "react-router-dom"

import list from '../../components/images/user-list.svg'
import search from '../../components/images/search.svg'

const IconList = styled.i`
  width: 24px;
  height: 24px;
  background-color: #212529;
${'' /* background-color: ${(props) => props.theme.fontSecondaryColor}; */}
  -webkit-mask: url(${list}) no-repeat center;
  mask: url(${list}) no-repeat center;
`
const IconSearch = styled.i`
  width: 24px;
  height: 24px;
  background-color: #212529;
${'' /* background-color: ${(props) => props.theme.fontSecondaryColor}; */}
  -webkit-mask: url(${search}) no-repeat center;
  mask: url(${search}) no-repeat center;
`

const activeClassName = "nav-item-active"
const StyledLink = styled(NavLink).attrs({activeClassName})`
${'' /* background-color: ${(props) => props.theme.btnBgNavColor}; */}
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 4px 10px 1px rgb(0 0 0 / 25%);
  transition: all 0.5s;
  text-decoration: none;
  width: 50%;
  margin: 0.5rem;
  color: ${(props) => props.theme.btnFontNavColor};

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    height: 3rem;
    padding-top: 0.5rem;
  }

  span {
    font-size: 0.75rem;
    transition: all 0.5s;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
  }


  &.${activeClassName} {
    div {
      transition: all 0.5s;
      background-color: #fa6742;
    ${'' /* color: ${(props) => props.theme.btnFontNavSelectColor}; */}
      border-radius: 3px;
    }

    span {
      font-size: 0.85rem;
      transition: all 0.5s;
      color: #F5F1E8;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
    }

    i {
      background-color: #F5F1E8;
    }
  }
`
const StyledBottomNav = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  background: white;
  @media screen and (min-width: 768px) {
    padding: 0 1rem 0.5rem 1rem;
    transition: all 0.5s;
  }
`
const CustomNav = styled.nav`
  background-color: #D1D1D1;
${'' /* background-color: ${(props) => props.theme.bgSecondaryColor}; */}
  border-radius: 3px;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: all 0.5s;
  width: 100%;
`

const BottomNav = () => {

  return (
    <React.Fragment>
      <StyledBottomNav>
        <CustomNav>
          <StyledLink to='/gamblers-list-mob'>
            <div>
              <IconList/>
              <span>LUDÓPATAS</span>
            </div>
          </StyledLink>
          <StyledLink to='/gamblers-query-mob'>
            <div>
              <IconSearch/>
              <span>CONSULTAR</span>
            </div>
          </StyledLink>
          <StyledLink to='/gamblers-ingress'>
            <div>
              <IconList/>
              <span>INGRESOS</span>
            </div>
          </StyledLink>
        </CustomNav>
      </StyledBottomNav>

    </React.Fragment>
  )
}

export default BottomNav
