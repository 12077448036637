import React, {useEffect, useState} from 'react'
import imageProfile from '../../components/images/man.svg'
import styled from 'styled-components'
import {DateTime} from "luxon";
import TimeAgo from "./TimeAgo";

const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0;
  margin: 0.25rem 0;
`
const ItemData = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
`
const ItemLocation = styled.span`
  font-size: 0.8rem;
  color: #5a5a5a;
`

const LudopathsList = ({data, searchTerm}) => {

  const [search, setSearch] = useState([])

  function getName(fullName) {
    let lastName1 = ""
    let lastName2 = ""

    if (fullName.lastName1 !== undefined && fullName.lastName1 !== null && fullName.lastName1 !== "") {
      lastName1 = fullName.lastName1 + " "
    }

    if (fullName.lastName2 !== undefined && fullName.lastName2 !== null && fullName.lastName2 !== "") {
      lastName2 = fullName.lastName2 + ", "
    }

    return fullName.document + " | " + lastName1 + lastName2 + fullName.firstName
  }

  var groupBy = (arr, prop) => {
    return arr.reduce((objs, obj) => {
      const key = obj[prop];
      if (key) {
        let fi = objs.findIndex(x => x.key === key);
        if (fi >= 0){
          objs[fi].values = [...objs[fi].values, obj]
        } else {
          objs.push({
            key: key,
            values: [obj]
          })
        }
      }
      return objs;
    },[]);
  }

  const groupedDate = (value) => {
    if (value === undefined || value === null){
      return []
    }
      const formatted = value.map((str) => {
        return {
          banListId: str.banListId,
          comment: str.comment,
          contact: str.contact,
          createdAt: DateTime.fromISO(str.createdAt).toFormat("yyyy'-'LL'-'dd"),
          document: str.document,
          documentType: str.documentType,
          firstName: str.firstName,
          id: str.id,
          imgUrl: str.imgUrl,
          lastName1: str.lastName1,
          lastName2: str.lastName2,
          listName: str.listName,
          location: str.location,
          minceturId: str.minceturId,
          publishedAt: str.publishedAt,
          salaId: str.salaId,
          updatedAt: str.updatedAt,
          ingress: str.ingress
        };
      })

      const newArray = groupBy(formatted, 'createdAt')

      const fecha = newArray.sort(function (a, b){
        if (a.key > b.key){
          return -1;
        }
        if (a.key < b.key){
          return 1;
        }
        return 0;
      })
      return fecha
  }

  const getData = (value) => {
    if (value) {
      let newArray = data.filter((val) => {
        if (searchTerm === '') {
          return val
        } else if (
          val.firstName.toLowerCase().includes(searchTerm?.toLowerCase()) ||
          val.lastName1?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          val.lastName2?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          val.location?.toLowerCase()?.includes(searchTerm?.toLowerCase()) ||
          val.document?.toLowerCase()?.includes(searchTerm?.toLowerCase())
        ) {
          return val
        }
      })
      return groupedDate(newArray)
    }
  }

  useEffect(() => {
    setSearch(groupedDate(data))
  }, []);

  useEffect(() => {
    if (data) {
      setSearch(getData(data))
    }
  }, [searchTerm]);

  return (
    <>
      {
        search.length > 0 &&
        <>
          {
            search.map(item =>
              <>
                <div style={{padding: '5px', fontWeight: "bold"}} key={item.key}>{item.key}</div>
                {
                  item.values.map((ludopata, i) =>
                    <ListItem key={i}>
                      <div>
                        {
                          ludopata.ingress ?
                            <div style={{paddingLeft: '5px', textAlign: 'center'}}>
                              <div>
                                <TimeAgo>{ludopata.ingress}</TimeAgo>
                              </div>
                              <div>
                                {DateTime.fromISO(ludopata.ingress).toFormat('HH:mm')}
                              </div>
                            </div>
                            :
                            <img src={imageProfile} alt="profile" width="40"/>
                        }
                      </div>
                      <ItemData>
                        <span>{getName(ludopata)}</span>
                        <ItemLocation>{ludopata.location}</ItemLocation>
                      </ItemData>
                    </ListItem>
                  )
                }
              </>
            )}
        </>
      }
    </>
  )
}

export default LudopathsList
