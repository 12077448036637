import React, {useContext, useEffect, useRef, useState} from "react"
import styled from "styled-components"
import {Apis} from "../util/Apis"
import Button from "../mobileComponents/generalComponents/Button"

import CircularProgress from "@material-ui/core/CircularProgress"
import CustomAppBar from "./generalComponents/CustomAppBar"
import CustomCard from "./generalComponents/CustomCard"

import check from './images/check.svg'
import close from './images/close.svg'
import BottomNav from "./generalComponents/BottomNav";
import {SiteContext} from "../selector/SiteContext";
import axios from "axios";

const CustomInput = styled.input`
  width: 100%;
  height: 45px;
  padding: 0 20px;
  font-size: 1rem;
  border: 1px solid #D0CFCE;
  outline: none;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%);
  border-radius: 5px;
  color: #5a5a5a;

  &:focus {
    border: 1px solid #182d94;
    transition: 0.35s ease;

    &::-webkit-input-placeholder {
      transition: opacity 0.45s ease;
      opacity: 0;
    }

    &::-moz-placeholder {
      transition: opacity 0.45s ease;
      opacity: 0;
    }

    &:-ms-placeholder {
      transition: opacity 0.45s ease;
      opacity: 0;
    }
  }
`

const ButtonContent = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`
const CustomButton = styled(Button)`
  width: 50%;
  margin: 0.5rem;
`

const SearchContent = styled.div`
  padding: 1rem;
  position: sticky;
  top: 4rem;
  background-color: white;
`
const TextError = styled.div`
  margin: 1rem;
  color: #f80c35;
`
const ResultContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 1rem;
  margin-bottom: 4rem;
`

const GamblerQuery = () => {
  const textInput = useRef(null);

  useEffect(() => {
    textInput.current.focus();
  }, []);

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false)

  const [document, setDocument] = useState('')
  const [sentDocument, setSentDocument] = useState('')

  const {salaId} = useContext(SiteContext)

  const queryByDocument = async (e) => {
    e.preventDefault()

    if (document.length < 6) {
      return
    }

    setLoading(true)
    const url = Apis.BASE + "/ban-list-members/query?salaId=" + salaId + "&document=" + document;
    const res = await axios.get(url);
    const {data} = await res;

    setData(data)
    setLoading(false)
    setSentDocument(document)

    setDocument('')
    textInput.current.focus();
  };

  const clearFn = () => {
    setData(null)
    setDocument('')
    textInput.current.focus();
  }

  const success = {
    color: "#3c763d",
    background: "#dff0d8",
    border: "1px solid #5e755eab",
    sombra: "0px 0 10px 5px #3c763d6b"
  };

  const danger = {
    color: "#f80c35",
    background: "#f8d7da",
    border: "1px solid #714d4dab",
    sombra: "0px 0 10px 5px #763f3c6b"
  };

  return (
    <>
      <CustomAppBar titulo={'Control de ingreso'}/>

      <SearchContent>
        <form onSubmit={(e) => queryByDocument(e)}>

          <CustomInput
            autoComplete="off"
            autoFocus
            type="tel"
            name="document"
            placeholder="Ingrese documento (min 8 dígitos)"
            id="inputludo"
            value={document}
            ref={textInput}
            onChange={(e) => setDocument(e.target.value)}
          />

          <ButtonContent>
            <CustomButton type="submit" className="btn-primary">Consultar</CustomButton>
            <CustomButton type="reset" onClick={clearFn} id="borrarludo"
                          className="btn-dark">Limpiar</CustomButton>
          </ButtonContent>
        </form>
      </SearchContent>

      <ResultContent>
        {loading ?
          <CircularProgress/>
          :
          <>
            {data != null &&
              <>
                {data.length > 0 ?
                  <>
                    {data[0].imgUrl !== 'imgUrl' && data[0].imgUrl !== null ?
                      <CustomCard theme={danger} img={data[0].imgUrl} titulo="ACCESO DENEGADO" document={sentDocument}
                                  person={data[0]}/>
                      :
                      <CustomCard theme={danger} img={close} titulo="ACCESO DENEGADO" document={sentDocument}
                                  person={data[0]}/>
                    }
                  </>
                  :
                  <CustomCard
                    theme={success}
                    img={check}
                    titulo="ACCESO PERMITIDO"
                    document={sentDocument}
                  />
                }
              </>
            }
          </>
        }
      </ResultContent>
      <BottomNav/>
    </>
  )
}

export default GamblerQuery
