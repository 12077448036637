import React from "react";
import './styles/Form.css'
import '../components/formComponents/styles/FormTitle.css'
import FormSquares from "./formComponents/FormSquares";
import './formComponents/styles/FormSquares.css'
import FormTitle from "./formComponents/FormTitle";
import {Link} from "react-router-dom";

class Form extends React.Component {

  handleChange = (e) => {
    // console.log({
    //     name:e.target.name,
    //     value:e.target.value})
    this.setState({
      sala: e.target.value,
    })
  }

  handleClick = e => {
    console.log("An event happend")
  }

  handleSubmit = e => {
    e.preventDefault();
    console.log("form was submitted")
  }

  render() {
    return (
      <div className="form-container-dev">
        <FormTitle
          title="Nueva Solicitud"
        />
        <React.Fragment onSubmit={this.handleSubmit}>
          <div className="medium-form-square">
            <FormSquares
              squareName="Apellido Paterno"
              name="lastName1"
              type="text"
            />
          </div>
          <div className="medium-form-square">
            <FormSquares
              squareName="Apellido Materno"
              name="lastName1"
              type="text"
            />
          </div>
          <div className="large-form-square">
            <FormSquares
              squareName="Nombres"
              name="firstName"
              type="text"
            />
          </div>
          <div className="small-form-square">
            <FormSquares
              squareName="Departamento"
              name="departamento"
              type="text"
            />
          </div>
          <div className="small-form-square">
            <FormSquares
              squareName="Provincia"
              name="provincia"
              type="text"
            />
          </div>
          <div className="small-form-square">
            <FormSquares
              squareName="Distrito"
              name="distrito"
              type="text"
            />
          </div>
          <br/>
          <div className="container-fluid" id="button">
            <div className="row">
              <div className="col-6">
                <button onClick={this.handleClick} className="btn btn-primary">Guardar</button>
              </div>
              <div className="col-6">
                <div className="">
                  <Link
                    to="/gamblers"
                    className="btn btn-primary"
                  >
                    Volver
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      </div>
    )
  }
}

export default Form
