import React from "react";
import '../styles/GamblerBadge.css'
import Person from "./Person";
import Location from "./Location";

class GamblerBadge extends React.Component {
  render() {
    return (
      <div className="gambler-container">
        <div className="photo-container">
          <img className="photo"
               src={"http://uploads.asis.la/persons/" + this.props.badge.person.photo}
               alt={"profilePicture"}
          />
        </div>
        <div className="numRegis">
          {this.props.badge.personId}
        </div>
        <Person
          classname="person"
          name={this.props.badge.person.firstName}
          lastName1={this.props.badge.person.lastName1}
          lastName2={this.props.badge.person.lastName2}
          document={this.props.badge.person.document}
        />
        {(this.props.badge.person.ubigeo !== null) && (
          <Location
            className="location"
            departamento={this.props.badge.person.ubigeo.departamento}
            provincia={this.props.badge.person.ubigeo.provincia}
            distrito={this.props.badge.person.ubigeo.distrito}
          />
        )
        }

        <div className="date">
          {this.props.badge.registeredAt}
        </div>
        <div className="container-fluid" id="">
          <button className="btn btn-primary">
            Edit
          </button>
          <button className="btn btn-primary">
            Remove
          </button>
        </div>
      </div>
    )
  }
}

export default GamblerBadge
