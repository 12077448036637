import React from "react";
import './styles/Person.css'
import icon from './images/id-icon.png'

class Person extends React.Component {
  render() {
    return (
      <div className="person-container">
        <div className="person-name-container">
          {this.props.lastName1} {this.props.lastName2},{this.props.name}
        </div>
        <div className="person-id-container">
          <img src={icon} alt="icon-id" className="person-id-image"/>
          <div className="person-id">
            {this.props.document}
          </div>
        </div>
      </div>
    )
  }
}

export default Person
