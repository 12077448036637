import React, {createContext, useEffect, useState} from 'react';
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";

export const SiteContext = createContext({
  salaId: null,
  info: {},
  setSalaInfo: () => {
  }
});

export const SiteProvider = (props) => {

  //TODO, moreInfo debe tener toda la data necesaria para guardarla en memoria, hacer query on refresh
  const [salaId, setSalaId] = useState(null)
  const [info, setInfo] = useState({})
  const [moreInfo, setMoreInfo] = useState({})

  const {loading, data, fetchData} = useFetch(Apis.USER + '/users/me', null)

  useEffect(() => {
    const sala = JSON.parse(localStorage.getItem('sala'))
    if (sala) {
      setInfo({sala: sala})
      setSalaId(sala.id)
    }
  }, [])

  useEffect(() => {
    setMoreInfo(data)
  }, [data])

  const setSalaInfo = (sala) => {
    localStorage.setItem('sala', JSON.stringify(sala))
    setInfo({sala: sala})
    setSalaId(sala.id)
  }

  return (
    <SiteContext.Provider value={{salaId, info, moreInfo, setSalaInfo}}>
      {
        loading ?
          <div>Cargando...</div> :
          props.children
      }
    </SiteContext.Provider>
  );
}

//export const CustomerConsumer = CustomerContext.Consumer;
