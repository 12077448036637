import React from "react";

class FormSquares extends React.Component {
  handleChange = (e) => {
    // console.log({
    //     name:e.target.name,
    //     value:e.target.value})
    this.setState({
      sala: e.target.value,
    })
  }

  handleClick = e => {
    console.log("An event happend")
  }

  handleSubmit = e => {
    e.preventDefault();
    console.log("form was submitted")
  }

  render() {
    return (
      <React.Fragment>
        <label>{this.props.squareName}</label>
        <input
          onChange={this.handleChange}
          className="form-control"
          type={this.props.type}
          name={this.props.name}>
        </input>
      </React.Fragment>
    )
  }
}

export default FormSquares
