import React from "react";

class FormTitle extends React.Component {
  render() {
    return (
      <div className="title-container">
        {this.props.title}
      </div>
    )
  }
}

export default FormTitle
