import React from "react";
import {DateTime} from "luxon";

const TimeAgo = ({children}) => {

  const goTime = (value) => {
    const today = DateTime.now().toLocaleString(DateTime.DATE_SHORT)
    const yesterday = DateTime.now().minus({day: 1}).toLocaleString(DateTime.DATE_SHORT)


    const day = DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT)

    if (day === today) {
      return 'Hoy'
    }

    if (day === yesterday) {
      return 'Ayer';
    }

    return DateTime.fromISO(value).setLocale('pe').toFormat('dd LLL');
  }

  return (
    <>
      {goTime(children)}
    </>
  )
}

export default TimeAgo
