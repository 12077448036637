import React from "react";
import GamblerBadge from "./GamblerBadge";
import '../styles/GamblersList.css'

class GamblersList extends React.Component {
  render() {
    return (
      <div className="list-container">
        <ul id="gamblers-list">
          {this.props.badges.map((badge) => {
            return (
              <li key={badge.id}>
                <GamblerBadge badge={badge}/>
              </li>
            )
          })}
        </ul>
      </div>
    )
  }

}

export default GamblersList
