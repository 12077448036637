import React from 'react'
import styled from 'styled-components'

const CustomContent = styled.div`
  top: 0;
  left: auto;
  right: 0;
  position: sticky;
  background-color: white;
  padding: 0.5rem;
`
const Header = styled.div`
  color: #fff;
  background: #fa6742;
  height: 3rem;
  display: flex;
  align-items: center;
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  border-radius:5px;
  padding: 0 1rem;
`

const CustomAppBar = ({titulo}) => {
  return (
    <CustomContent>
      <Header>
        {titulo}
      </Header>
    </CustomContent>
  )
}

export default CustomAppBar
