import styled from 'styled-components'

const Button = styled.button`
    text-transform: uppercase;
    display: inline-block;
    vertical-align: bottom;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%), 0 2px 10px 0 rgb(0 0 0 / 10%);
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: .75rem;
    line-height: 1.5;
    color: #4f4f4f;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    user-select: none;
    background-color: transparent;
    border: .125rem solid transparent;
    padding: .375rem .75rem;
    border-radius: .25rem;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;

    &.btn-primary{
      color:#fff;
      background-color:#fa6742 !important;
    }
    &.btn-primary:hover,&.btn-primary:focus {
    color: #fff;
    background-color: #0c56d0 !important;
    }
    &.btn-dark{
      color:#fff;
      background-color: #262626 !important;
    }
    &.btn-dark:hover,&.btn-dark:focus {
    color: #fff;
    background-color: #131313 !important;
    }
    &.btn-block{
      width:100%;
    }
`

export default Button

