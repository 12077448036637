import React, {useContext, useState} from 'react';
import {SiteContext} from "../selector/SiteContext";
import useFetch from "../hooks/useFetch";
import {Apis} from "../util/Apis";
import CustomAppBar from "./generalComponents/CustomAppBar";
import {CircularProgress} from "@material-ui/core";
import LudopathsList from "./generalComponents/LudopathsList";
import BottomNav from "./generalComponents/BottomNav";
import {CustomInput, ListContent, SearchContent} from "./GamblerList";
import styled from "styled-components";

const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
`

const GamblerIngress = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const {salaId} = useContext(SiteContext)

  const {data, loading} = useFetch(Apis.BLACK + "/customer-ingress/last?salaId=" + salaId)

  const dataIngress = []

  const getData = (value) => {
    if (value) {
      value.map(item =>
        dataIngress.push({
          id: item.id,
          firstName: item.firstName?.toUpperCase(),
          lastName1: item.lastName1?.toUpperCase(),
          lastName2: item.lastName2?.toUpperCase(),
          document: item.document,
          location: item.departamento ? item.departamento?.toUpperCase() + '/' + item.provincia?.toUpperCase() + '/' + item.distrito?.toUpperCase():"",
          ingress: item.createdAt,
          createdAt: item.createdAt
        })
      )
      return dataIngress
    }
  }

  return (
    <>
      <CustomAppBar titulo={'Ingresos a sala'}/>
      <SearchContent>
        <CustomInput placeholder="Buscar por nombre o documento"
                     onChange={event => {
                       setSearchTerm(event.target.value)
                     }}
                     autoComplete="off"
                     type="text"
        />
      </SearchContent>
      <ListContent>
        {loading ?
          <CircularProgress color="secondary"/>
          :
          <div style={{width: '100%'}}>
            {
              getData()?.length === 0 ?
                <div>
                  <EmptyState>
                    <img
                      src="https://res.cloudinary.com/dghqjv5az/image/upload/v1665533533/wgtassets/runner/art-4_y057yj.jpg"
                      alt="empty-state"/>
                  </EmptyState>
                  <EmptyState>
                    No hay ingresos registrados para este dia
                  </EmptyState>
                </div>
                :
                <LudopathsList data={getData(data)} searchTerm={searchTerm}/>
            }
          </div>

        }
      </ListContent>
      <BottomNav/>
    </>
  )
};

export default GamblerIngress;
