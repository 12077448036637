import React from 'react'
import {Redirect, Route, Switch} from 'react-router'
import Form from "./components/Form"
import NotFound from "./components/NotFound"
import Gamblers from "./components/Gamblers"
import GamblerList from "./mobileComponents/GamblerList"
import GamblerQuery from "./mobileComponents/GamblerQuery"
import GlobalStyle from './globalStyles'
import LoginPage from "./login/LoginPage";
import CallbackPage from "./login/CallbackPage";
import LogoutPage from "./login/LogoutPage";
import PrivateRoute from "./auth/PrivateRoute";
import WauthProvider from "./auth/WauthProvider";
import SalaSelector from "./selector/SalaSelector";
import axios from "axios";
import {SiteProvider} from "./selector/SiteContext";
import {Apis} from "./util/Apis";
import GamblerIngress from "./mobileComponents/GamblerIngress";

axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem('auth');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config;
});

axios.interceptors.response.use((response) => {
  return response;
}, async (error) => {
  const originalConfig = error.config;
  if (error.response) {
    if (error.response.status === 401 && originalConfig.url === Apis.OAUTH + '/api/auth/refreshtoken') {
      window.location = '/logout';
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      const rs = await axios.post(Apis.OAUTH + "/api/auth/refreshtoken", {
        refresh_token: localStorage.getItem("wsrf"),
        client_id: process.env.REACT_APP_AUTH_CLIENT_ID
      });
      const {accessToken} = rs.data;
      localStorage.setItem("auth", accessToken)
      return axios(originalConfig);
    } else {
      return Promise.reject(error);
    }
  } else {
    return Promise.reject(error);
  }
});

function App() {
  return (
    <WauthProvider
      domain={process.env.REACT_APP_AUTH_SERVER}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
      redirectUri={window.location.origin + '/callback'}
    >
      <GlobalStyle/>
      <Switch>
        <Route exact path="/login" component={LoginPage}/>
        <Route exact path="/callback" component={CallbackPage}/>
        <Route exact path="/logout" component={LogoutPage}/>
        <Route path='/' component={SecureApp}/>

        <Route component={NotFound}/>
      </Switch>
    </WauthProvider>
  );
}

const SecureApp = () => {
  return (
    <SiteProvider>
      <Switch>
        <PrivateRoute exact path="/gamblers" component={Gamblers} name="gamblers"/>
        <PrivateRoute exact path="/gamblers-list-mob" component={GamblerList} name="gambler-list"/>
        <PrivateRoute exact path="/gamblers-query-mob" component={GamblerQuery}/>
        <PrivateRoute exact path="/gamblers-ingress" component={GamblerIngress}/>
        <PrivateRoute exact path="/form" component={Form}/>
        <PrivateRoute exact path="/selector" component={SalaSelector}/>

        <Route exact path="/">
          <Redirect to="/gamblers-list-mob"/>
        </Route>
      </Switch>
    </SiteProvider>
  )
};

export default App;
