import React, {useContext} from "react";
import styled from 'styled-components'
import {useHistory, useLocation} from "react-router";
import Card from "./Card";
import {SiteContext} from "./SiteContext";
import logo from '../assets/logowhite.png'
import {Apis} from "../util/Apis";
import useFetch from "../hooks/useFetch";

const SalaSelectorStyled = styled.div`

  .office-container {
    width: 100%;
    max-width: 1000px;
    height: 100%;
    padding: 30px 10px;

    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(150px, 230px));
    //grid-template-rows: repeat(auto-fit, minmax(150px, 230px));
    grid-template: fit-content;

    justify-content: center;
    margin: 0 auto;
  }

  .office-item {
    //width: 100%;
    //min-height: 150px;
    //display: flex;
    //flex-direction: column;
    //justify-content: space-between;
    //align-items: center;
    //padding: 10px;
    //cursor: pointer;
    //transition: all .3s ease;
    //
    //border : 1px solid gray;
    //border-radius: 5px;

    //&:hover{
    //  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, .3);
    //}
    &:hover img {
      filter: grayscale(20%);
    }

    &:hover &__name {
      transform: scale(1.1);
    }

    &__logo {
      box-sizing: border-box;
      overflow: hidden;
      min-height: 130px;
      display: flex;
      align-items: center;

      img {
        max-width: 100%;
        object-fit: cover;
        filter: grayscale(100%);
        transition: all .3s ease;
      }
    }

    &__name {
      font-size: 1.1rem;
      transition: all .3s ease;
      font-weight: bold;
      color: rgb(36, 40, 44);
      text-align: center;
    }
  }
`

const SalaSelector = () => {

  const history = useHistory()
  const location = useLocation()
  const {setSalaInfo} = useContext(SiteContext)

  const {loading, data} = useFetch(Apis.USER + '/users/me', null)

  function select(sala) {
    setSalaInfo(sala)

    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get('redirect')

    if (redirect) {
      history.push(redirect)
    } else {
      history.push('/')
    }
  }

  return (
    <React.Fragment>
      <h1 style={{
        fontSize: "24px",
        textAlign: "center",
        paddingTop: "50px",
        marginBottom: "50px",
        fontFamily: "'Noto Sans JP', sans-serif"
      }}>Seleccione una sala </h1>
      <SalaSelectorStyled>
        <div className="office-container" style={{overflow: "auto", height: "75vh"}}>
          {data && data.salas.map(item =>
            <Card key={item.id} padding style={{cursor: 'pointer', maxHeight: "220px"}} onClick={() => select(item)}>
              <div className='office-item'>
                <div className="office-item__logo">
                  <img src={item.logo ? item.logo : logo}/>
                </div>
                <div className="office-item__name">
                  <span>{item.name}</span>
                </div>

              </div>
            </Card>
          )}
        </div>
      </SalaSelectorStyled>
    </React.Fragment>
  )
}

export default SalaSelector;
