import React from 'react'
import styled, {ThemeProvider } from 'styled-components'

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-clip: border-box;
  border: ${props => props.theme.border};
  border-radius: .25rem;
  box-shadow: ${props => props.theme.sombra};
  width:100%;
`
const CardHeader = styled.div`
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0,0,0,.03);
  border-bottom: 1px solid rgba(0,0,0,.125);
  text-align:center;
  font-weight: 700;
  font-size:1.5rem;
  color: ${props => props.theme.color};
  background-color: ${props => props.theme.background};

`
const CardBody = styled.div`
  flex: 1 1 auto;
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
`
const SubTitle = styled.div`
  margin-top:1rem;
  text-align:center;
  font-size: 1.2rem;
  font-weight: 600;
  color: ${props => props.theme.color};
`
const ImgContent = styled.div`
  text-align: center;
`

const Contenido = styled.div`
  display:flex;
  flex-direction: column;
  margin: 2rem 1rem 0;
  span{
    color: ${props => props.theme.color};
  }
`

CardHeader.defaultProps = {
  theme: {
    color: "#212529",
    background: "#00000008",
    border: "1px solid rgba(0,0,0,.125)",
    sombra: "0px 2px 4px -1px rgb(0 0 0 / 20%)"
  }
}

const CustomCard = ({theme, img, titulo, subtitulo, document, person}) => {

  console.log(person)

  function getName(fullName) {
    let lastName1 = ""
    let lastName2 = ""

    if (fullName.lastName1 !== undefined && fullName.lastName1 !== null) {
      lastName1 = fullName.lastName1 + " "
    }

    if (fullName.lastName2 !== undefined && fullName.lastName1 !== null) {
      lastName2 = fullName.lastName2 + ", "
    }

    return lastName1 + lastName2 + fullName.firstName
  }

  return (
    <ThemeProvider theme={theme}>
      <Content>
        <CardHeader>{titulo}</CardHeader>
        <CardBody>
          <ImgContent>
            {img &&  <img src={img} alt='result' width='80px' /> }
          </ImgContent>
          <SubTitle>{document}</SubTitle>
          {person &&
            <Contenido>
              <span>{getName(person)}</span>
              <span>{person.listName}</span>
              <span>{"Ubicación: " + person.location}</span>
              <span>{"Comentario: " + person.comment}</span>
            </Contenido>
          }
        </CardBody>
      </Content>
    </ThemeProvider>
  )
}

export default CustomCard
