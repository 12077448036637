import React from "react";
import Titles from "./badgeComponents/Titles";
import GamblersList from "./badgeComponents/GamblersList";
import {Link} from "react-router-dom";
import {Apis} from "../util/Apis";
import BottomNav from "../mobileComponents/generalComponents/BottomNav";
import axios from "axios";

class Gamblers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        content: [],
      },
    }
  }

  componentDidMount() {
    this.fetchGamblers()
  }

  fetchGamblers = async () => {
    const banListId = "5f2c9bc0ccd74b07f0e929d6";

    const url = Apis.BASE + "/ban-list/" + banListId + "/members?page=1&size=10"
    const res = await axios.get(url);
    const { data } = await res;

    this.setState({
      data: data
    })
  }

  render() {
    return (
      <div>
        <Titles/>
        <GamblersList badges={this.state.data.content}/>
        <div className="container-fluid" id="button">
          <div className="row">
            <Link
              to="/form"
              className="btn btn-primary">
              Nueva Solicitud
            </Link>
          </div>
        </div>
        <BottomNav/>
      </div>
    )
  }
}

export default Gamblers
