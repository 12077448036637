import styled, {css} from 'styled-components'
import React from "react";

const withPadding = css`
  //padding: 1.8rem; //shopify
  padding: 16px; //ecwind
  
  @media screen and (min-width: 768px){
    padding: 20px 24px;
  }
`;

const CardStyled = styled.div`
    position: relative;
    box-sizing: border-box;
    margin-bottom: 16px;
    padding: 0;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1), 0 0 0 rgba(0,0,0,0.08) inset;
    transition: box-shadow .2s linear, border-color .2s linear, background-color .2s linear;
    
    ${({ padding }) => (padding ? withPadding : '')}
`

const Card = ({padding = false, style, children, onClick}) => {
  return (
    <CardStyled style={style} padding={padding} onClick={onClick}>
      {children}
    </CardStyled>    
  )
}

export default Card
