import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  * {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		font-family: 'Lato', sans-serif;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		color: #212529;
	} 
`

export default GlobalStyle